// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'redeem_code'
  }, {
    prop: 'redeemed_at'
  }, {
    prop: 'remark'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }]
}
